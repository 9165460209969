.seyv {
  fill: white;
  cursor: pointer;
  transition: 0.7s;
  &:hover {
    fill: #c8cfdb;
  }
}
.active {
  transition: 0.7s;
  fill: rgb(184, 182, 207);
  cursor: pointer;
}
